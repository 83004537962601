<template>
  <HardwareLayout v-if="true">
    <b-card
      :title="'Editar Hardware'"
      class="user-create-card"
      align="left"
    >
      <HardwareForm
        v-if="!!this.form"
        ref="hardwareForm"
        :form_prop.sync="form"
      />
      <b-row class="footer">
        <b-col>
          <b-button variant="secondary" @click="onRemove" class="d-flex">
            <b-icon-trash></b-icon-trash>
          </b-button>
        </b-col>
        <b-col class="footer-buttons">
          <b-button variant="primary" @click="onSave()">
            Guardar cambios
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <RemoveConfirmationModal
      :id="confirmationModalId"
      @onConfirmRemoveConfirmation="onConfirmedRemove"
      @onCancelRemoveConfirmation="onCancelRemove"
    />
  </HardwareLayout>
</template>

<script>
import HardwareLayout from '../layout/HardwareLayout.vue'
import HardwareForm from '@/components/Business/Hardware/HardwareForm/HardwareForm.vue'
import { BIconTrash } from 'bootstrap-vue'
import { getHardwareApi, updateHardwareApi, deleteHardwareApi } from '@/api/business/AvifyHardwareApi'
import RemoveConfirmationModal from '@/components/Modal/RemoveConfirmationModal'

export default {
  name: 'HardwareEdit',
  components: {
    HardwareLayout,
    HardwareForm,
    BIconTrash,
    RemoveConfirmationModal
  },
  data () {
    return {
      form: null
    }
  },
  async created () {
    this.lockScreen()
    await getHardwareApi(this.$route.params.id).then(response => {
      this.form = response
    })
    this.unlockScreen()
  },
  computed: {
    confirmationModalId () {
      return 'remove-confirmation' + 'id'
    }
  },
  methods: {
    async onSave () {
      const isFormValid = await this.$refs.hardwareForm.$refs.hardwareForm.validate()
      if (!isFormValid) {
        return
      }
      this.lockScreen()
      const hardwareToUpdate = { ...this.form }
      hardwareToUpdate.brandId = this.form.brand.id
      hardwareToUpdate.productTypeId = this.form.productType.id
      delete hardwareToUpdate.brand
      delete hardwareToUpdate.productType
      updateHardwareApi(hardwareToUpdate)
        .then(() => {
          this.unlockScreen()
          this.$store.dispatch('showConfirmationModal')
          this.$router.push({ name: 'hardware-list' })
        })
    },
    onRemove () {
      this.$bvModal.show(this.confirmationModalId)
    },
    onCancelRemove () {
      this.$bvModal.hide(this.confirmationModalId)
    },
    onConfirmedRemove () {
      this.lockScreen()
      deleteHardwareApi(this.form.id)
        .then(() => {
          this.onCancelRemove()
          this.unlockScreen()
          this.$store.dispatch('showConfirmationModal')
          this.$router.push({ name: 'hardware-list' })
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import "hardwareEdit";
</style>
